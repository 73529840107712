import {Fragment, useState} from 'react';
import {baseURL} from '../../service/api';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';

import {InvoiceService} from '../../service/InvoiceService';

import {HoaDon} from './HoaDon';

const invoiceService = new InvoiceService();

export const TraCuu = () => {

	const [value, setValue] = useState('');
	const [referenceNumber, setReferenceNumber] = useState('');
	const [message, setMessage] = useState(null);
	const [headerInfo, setHeaderInfo] = useState({});
	const [display, setDisplay] = useState(0);

	const lockup = () => {
		if (value) {
			window.open(`${baseURL}iam-entry/cert-income-tax/${value}/pdf`);
		}
	}

	return (
			<Fragment>
				{/*<HoaDon referenceNumber={referenceNumber} display={display} setDisplay={setDisplay} headerInfo={headerInfo}/>*/}
				<div className="layout-tra-cuu flex justify-content-center">
					<div className="flex align-items-center justify-content-center col-12 md:col-6 lg:col-5">
						<div className="grid">
							<div className="col-12">
								<div className="text-white text-center">
									<h4 className="font-medium text-2xl m-0">TRA CỨU CHỨNG TỪ TNCN<br/>MYPIT</h4>
								</div>
							</div>
							<div className="p-fluid formgrid col-12">
								<InputText value={value} onChange={(e) => setValue(e.target.value)} placeholder="Nhập mã tra cứu"/>
							</div>
							{message && (
									<div className="col-12">
										<div className="p-message p-component p-message-error p-message-enter-done m-0">
											<div className="p-message-wrapper">
												<span className="p-message-icon pi pi-times-circle"/>
												<span className="p-message-detail">{message}</span>
											</div>
										</div>
									</div>
							)}
							<div className="col-12">
								<div className="text-center">
									<Button label="Tra cứu" icon="pi pi-search" className="p-button-success" onClick={lockup}/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="layout-loi-ich">
					<h2 className="text-center mt-7 mb-0">Lợi ích hóa đơn điện tử</h2>
					<div className="p-3">
						<div className="grid">
							<div className="col-12 md:col-3 lg:col-3">
								<div className="item">
									<div className="item-img">
										<img src={'/assets/images/nghiep-vu.png'} alt=""/>
									</div>
									<div className="item-title text-center m-3">
										ĐÁP ỨNG NGHIỆP VỤ
									</div>
									<div className="item-text text-center">
										Đảm bảo đáp ứng toàn bộ nghiệp vụ theo quy định của Tổng cục thuế
									</div>
								</div>
							</div>
							<div className="col-12 md:col-3 lg:col-3">
								<div className="item">
									<div className="item-img">
										<img src={'/assets/images/hieu-qua.png'} alt=""/>
									</div>
									<div className="item-title text-center m-3">
										TIẾT KIỆM - HIỆU QUẢ
									</div>
									<div className="item-text text-center">
										Tiết kiệm 90% chi phí in ấn, vận chuyển, lưu trữ và bảo quản hóa đơn
									</div>
								</div>
							</div>
							<div className="col-12 md:col-3 lg:col-3">
								<div className="item">
									<div className="item-img">
										<img src={'/assets/images/an-toan.png'} alt=""/>
									</div>
									<div className="item-title text-center m-3">
										AN TOÀN - BẢO MẬT
									</div>
									<div className="item-text text-center">
										Sử dụng công nghệ bảo mật nhiều lớp, an toàn tuyệt đối trong việc quản lý và lưu trữ.
									</div>
								</div>
							</div>
							<div className="col-12 md:col-3 lg:col-3">
								<div className="item">
									<div className="item-img">
										<img src={'/assets/images/tu-van.png'} alt=""/>
									</div>
									<div className="item-title text-center m-3">
										TƯ VẤN - HỖ TRỢ
									</div>
									<div className="item-text text-center">
										Triển khai nhanh, hỗ trợ thông báo phát hành hóa đơn, tư vấn nghiệp vụ trong việc sử dụng hóa đơn.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
	);
}
